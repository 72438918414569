'use strict';
var address = require('./address'),
	billing = require('./billing'),
	multiship = require('./multiship'),
	shipping = require('./shipping'),
	addToCart = require('../product/addToCart'),
	multishipment = require('./multishipment'),
	hellmannAVS = require('../../hellmannAVS'),
	formPrepare = require('./formPrepare'),
	customTaxID = require('./customTaxID'),
    melissa = require('./melissa');
/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
	address.init();
    melissa.init();
	if ($('.checkout-shipping').length > 0) {
		shipping.init();
	} else if ($('#multi-shipment-shipping-method-list').length > 0) {
		multishipment.init();
	} else if ($('.checkout-multi-shipping').length > 0) {
		multiship.init();
	} else if ($('.checkout-billing').length > 0) {
		billing.init();
	} else if ($('.checkout-hellmann-avs').length > 0) {
		hellmannAVS.init();
	}
	addToCart();
	//if on the order review page and there are products that are not available diable the submit order button
	if ($('.order-summary-footer').length > 0) {
		if ($('.notavailable').length > 0) {
			$('.order-summary-footer .submit-order .button-fancy-large').attr('disabled', 'disabled');
		}
		if ($('.customet-tax-section').length > 0) {
			// Initialize the billing form on the page
			$('.order-summary-footer .submit-order .button-fancy-large').attr('disabled', 'disabled');
			customTaxID.initCustomTaxIDFieldValidation('.submit-order');
			formPrepare.init({
				formSelector: 'form.submit-order',
				continueSelector: '[name$="submit"]',
				canDisable: true
			});
		}
	}
};
