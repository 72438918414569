'use strict';
var util = require('../../util'),
	ajax = require('../../ajax');
/**
 * @function
 * @description Melissa service call for list of address
 */
function getAddressAutocomplete() {
	window.globalTimeout = null;
	var $form = $('.address');
	var address1 = $form.find('input[name$="_postal"]').val();
	var country = $form.find('select[name$="_country"]').val();
	if (address1.length < 3) {
		$('.melissa-address').remove();
		return;
	}
	var url = Urls.findAddresForAutocomplete;
	address1 = $.trim(address1);
	var urlParam = address1.replace(/\s/g, '+');
	util.appendParamsToUrl(url);
	ajax.getJson({
		url: url,
		data: {address1: urlParam, country: country},
		callback: function (data) {
			if (data && data.response && Array.isArray(data.response) && data.response.length > 0) {
                var city = data.response[0].city.normalize('NFKD');
                var state = data.response[0].state.normalize('NFKD');
                var town = data.response[0].town.normalize('NFKD');
                var postalCode = data.response[0].postalCode.normalize('NFKD');

                var needUpdate = $('.address input[name$="_town"]').val() != town
                    || $('.address input[name$="_city"]').val() != city
                    || $('.address [name$="_state"]').val() != state
                    || $('.address [name$="_postal"]').val() != postalCode;

                $('.address input[name$="_city"]').val(city);
                $('.address input[name$="_postal"]').val(postalCode);
                $('.address [name$="_state"]').val(state);
                $('.address [name$="_town"]').val(town);
                if (needUpdate) {
                    $('.address input[name$="_postal"]').trigger('change');
                }
			}
			if (!data) {
				console.log('Couldn\'t get list of address');
				return false;
			}
		}
	});
}
exports.init = function init () {
	if (!SitePreferences.MELISSA_ENABLED) {
		return;
	}

	window.melissaAddressEditTimeout = null;
	$(document).on('click', 'button.melisa-data-button', function () {
		if (window.melissaAddressEditTimeout != null) {
			clearTimeout(window.melissaAddressEditTimeout);
		}
		window.melissaAddressEditTimeout = setTimeout(function () {
			window.melissaAddressEditTimeout = null;
			getAddressAutocomplete();
		}, 500);
	});
	$(document).on('click touch', function (e) {
		var dropdownElement = $('.melissa-address');
		if (!dropdownElement.is(e.target) && dropdownElement.has(e.target).length === 0) {
			dropdownElement.remove();
		}
	});
};
